import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"

import Table from "../images/table.svg"
import Seo from "../components/Seo"

export default function Resources() {
  return (
    <React.Fragment>
      <Seo title={"Resources"} />
      <Navbar />
      <main className="marginTopNavbar">
        <div className="main-container">
          <section className="overview">
            <div id="facilities-and-equipment" className="offset" />
            <div className="grid-8-col">
              <div className="col-3 ">
                <h1 className="sticky">Facilities and Equipment</h1>
              </div>

              <div className="col-4">
                <p>
                  The lab is located at Biocant, nearby Coimbra (Center of
                  Portugal), in a new state-of-the-art research facility (see
                  http://www.biocant.pt)
                </p>
                <br />
                <p>
                  The lab is equipped with general chemical synthesis and
                  characterization equipment, laminar flow hoods, CO2
                  incubators, phase inverted microscopes, spin coater, rotovap,
                  and other chemistry and cell biology equipment.
                </p>
                <br />
                <p>
                  Researchers have access to flow cytometry, fluorescence
                  microscopy, scanning confocal microscopy, quantitative RT-PCR,
                  electrophoresis apparatus, HPLC, freeze-dryer, NMR, and
                  microbiology facilities.
                </p>
                <br />
                <StaticImage
                  draggable="false"
                  className="image-border"
                  src="../images/facilities.jpg"
                  alt="Facilities"
                  layout="fullWidth"
                />
              </div>
            </div>
          </section>
          <div className="horizontal-line" />
          <section className="overview">
            <div id="high-throughput-screening-facility" className="offset" />
            <div className="grid-8-col">
              <div className="col-3 ">
                <h1 className="sticky">High-throughput screening facility</h1>
              </div>

              <div className="col-4">
                <p>Our group is responsible for the management of the HTSF.</p>
                <br />
                <p>
                  HTSF will offer high-content and high-troughput screening
                  services both at cellular level (based in microscopy) and gene
                  level (based in PCR). Laser microdissection technique allows
                  the isolation of uncontaminated samples (sample purification)
                  and single cells for downstream applications. Furthermore,
                  HTSF offers confocal microscopy services for target
                  validation. Both the high-content imaging microscope and the
                  confocal microscope have environmental control and allow the
                  performance of long-term live cell experiments. HTSF will also
                  offer training and technological innovation to the academic
                  and industrial communities.
                </p>
                <br />
                <p>
                  <span>Resources</span> <br />
                  → Laser scanning confocal microscope (LSM 710, Zeiss) <br />
                  → P.A.L.M. laser microdissection microscope (Zeiss) <br />
                  → Automated widefield high-content imaging microscope (In Cell
                  Analyzer 2200, GE Healthcare)
                  <br />
                  → High-throughput PCR for real-time PCR, end-point PCR and
                  digital PCR, based in Integrated fluidic circuit
                  technology(BioMark HD System, Fluidigm)
                  <br />→ Imaging analysis: In Cell Investigator (GE
                  Healthcare), FIJI/ImageJ
                </p>
                <br />
                <p>
                  <span>Booking and Fees</span> <br />
                  (Rules to access HTSF resources and Fees) <br />
                  → All users will have to book their session in an on-line
                  calendar (Agendo) or with the technician, and they cannot
                  exceed the booked time. <br />
                  → Reservations can be cancelled until 24h before the booked
                  time. → Unused reservations will be fully charged.
                  <br />
                  → The sessions in the laser microdissection, In Cell Analyzer
                  2200 and Biomark HD will always be carried out by a HTSF
                  technician.
                  <br />
                  → A HTSF technician will always monitor the first session of a
                  new user in the confocal microscope LSM 710.
                  <br /> → Users data will be erased from the PCs every 2 weeks.
                  <br /> → Biomark HD equipment and InCell analyser can be
                  utilized based upon a collaboration, for more information
                  please contact the technician.
                </p>
                <br />
                <StaticImage
                  draggable="false"
                  className="image-border"
                  src="../images/HTSF.png"
                  layout="fullWidth"
                  alt="HTSF"
                />
              </div>
            </div>
          </section>
          <br />
          <div className="col-8">
            <img width="100%" src={Table} alt="" />
            <br />
            <br />
            <br />
            <p>
              * The oligonucleotides to be analyzed and Gene Expression Array
              have to be acquired by the client; ** The cost per sample includes
              sample processing.
              <br />
              <br />
              <span>
                For more information contact Susana Simões
                (susana.simoes@cnc.uc.pt)
              </span>
            </p>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  )
}
